import { breakpoints } from './constants';

export const videoController = (wrapperSelector, controllerSelector) => {
  const wrappers = document.querySelectorAll(wrapperSelector);
  const controllers = document.querySelectorAll(controllerSelector);

  if (wrappers.length === 0 || controllers.length === 0) {
    return;
  }

  wrappers.forEach((wrapper, index) => {
    const controller = controllers[index];
    const video = wrapper.getElementsByTagName('video')[0];

    const controlState = () => {
      wrapper.addEventListener('click', () => {
        if (video.paused) {
          controller.checked = false;
          video.play();
        } else {
          controller.checked = true;
          video.pause();
        }
      });
    };

    const playVideoOnBreakpoints = () => {
      const breakpoint = `(max-width: ${breakpoints.DESKTOP_BREAKPOINT})`;
      const mobileView = window.matchMedia(breakpoint);

      if (mobileView.matches) {
        video.setAttribute('poster', video.getAttribute('data-poster'));
      } else {
        video.play();
      }
    };

    controlState();
    playVideoOnBreakpoints();
  });
};
