import Plyr from 'plyr';

const player = new Plyr('#player', {
  controls: [
    'play',
    'current-time',
    'duration',
    'progress',
    /// You can add more controls as many as you want ///
  ],
  invertTime: false,
});

if (document.querySelector('.section-audio') != null) {
  const audioElem = document.querySelector('.section-audio .playaudio-container');
  const stickyaudio = audioElem.getBoundingClientRect();
  const fixedClass = 'audio--fixed';
  const hideAudio = document.getElementById('hide-audio');
  const AudioOffsetTop = stickyaudio.top + window.scrollY;

  let updating = false;

  const handleScroll = () => {
    if (window.pageYOffset >= AudioOffsetTop + stickyaudio.height) {
      updating = true;
      audioElem.classList.add(fixedClass);
      hideAudio.onclick = () => {
        audioElem.style.visibility = 'hidden';
        audioElem.style.opacity = '0';
        player.pause();
      };
    } else {
      audioElem.classList.remove(fixedClass);
      audioElem.style.visibility = 'visible';
      audioElem.style.opacity = '1';
      updating = false;
    }
  };
  // on scroll, if an update opportunity is available, update

  player.on('play', (event) => {
    window.onscroll = () => {
      handleScroll();
      updating = true;
    };

    const instance = event.detail.plyr;
    const progress = ((instance.currentTime / instance.duration) * 100).toFixed(0);

    tryToSubmitEvent('audio_play', {
      source: instance.source,
      current_time: instance.currentTime.toFixed(0),
      progress,
    });
  });

  // Sent when playback is paused.
  player.on('pause', (event) => {
    window.onscroll = () => {
      if (updating = false || window.pageYOffset <= AudioOffsetTop + window.innerHeight - stickyaudio.height) {
        audioElem.classList.remove(fixedClass);
        audioElem.style.visibility = 'visible';
        audioElem.style.opacity = '1';
      }
    };

    const instance = event.detail.plyr;
    const progress = ((instance.currentTime / instance.duration) * 100).toFixed(0);

    if (!instance.ended) {
      tryToSubmitEvent('audio_paused', {
        source: instance.source,
        current_time: instance.currentTime.toFixed(0),
        progress,
      });
    }
  });

  // Sent when playback completes.
  player.on('ended', (event) => {
    const instance = event.detail.plyr;

    tryToSubmitEvent('audio_ended', {
      source: instance.source,
    });
  });
}

const tryToSubmitEvent = (eventName, eventParams) => {
  try {
    // eslint-disable-next-line no-undef
    gtag('event', eventName, eventParams);
  } catch (e) {
    // Uncaught error. Google analytics couldn't be loaded on this page. Mostly due to cookie compliance or ad blockers.
  }
};
