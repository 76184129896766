if (window.location.href.includes('/find-work/')) {
  // Extract the jobID from the URL using regex
  const urlMatch = window.location.href.match(/\/find-work\/(\d+)\/?/);
  sessionStorage.setItem('directly_from_job', '');

  document.addEventListener('DOMContentLoaded', () => {
    if (urlMatch && urlMatch[1]) {
      const jobId = urlMatch[1];
      localStorage.setItem('jobId', jobId);
      const agentLink = document.querySelector('.job-hero__agent');

      if (agentLink) {
        agentLink.addEventListener('click', () => {
          sessionStorage.setItem('directly_from_job', 'Yes');
        });
      }
    }
  });
}
