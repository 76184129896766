import Cookies from 'js-cookie';

const handleCloseClick = (event) => {
  const container = event.target.closest('.m-alert-banner');
  const innerContainer = container.querySelector('.m-alert-banner__inner');
  const button = container.querySelector('.close-button');
  const cookieTitle = container.dataset.slug;

  innerContainer.style.opacity = '0';
  button.style.opacity = '0';

  container.classList.add('disappearing');
  setTimeout(() => { container.style.display = 'none'; }, 600);
  Cookies.set(cookieTitle, 'true', { expires: 30 });
};

export const initializeAlert = () => {
  const navbar = document.getElementById('navbar');

  function resize() {
    if (window.innerWidth < 1090) {
      navbar.classList.add('navbar-alert-height');
    } else {
      navbar.classList.remove('navbar-alert-height');
    }
  }

  window.addEventListener('resize', resize);
  const elements = document.getElementsByClassName('m-alert-banner');
  Array.from(elements).forEach((element) => {
    const button = element.querySelector('.close-button');
    button.addEventListener('click', handleCloseClick);
  });
};
