const agentCards = document.getElementById('agent-loop');
const navList = document.getElementsByClassName('tabs-nav__ul__li');
const tabs = document.getElementsByClassName('tab-content');
const mobileSelect = document.querySelector('.tabs-nav__dropdown--contact');

if (agentCards != null && mobileSelect != null) {
  agentCards.classList.add('hide');
  mobileSelect.addEventListener('change', (event) => {
    Array.from(tabs).forEach((element) => {
      if (event.target.value == element.getAttribute('data-panel-id')) {
        element.classList.add('hide');
      } else {
        element.classList.remove('hide');
      }
    });
  });

  for (let i = 0; i < navList.length; i++) {
    navList[i].addEventListener('click', function () {
      const current = document.getElementsByClassName('open');
      current[0].className = current[0].className.replace(' open', '');
      this.className += ' open';
      Array.from(tabs).forEach((element) => {
        if (this.getAttribute('data-panel-id') == element.getAttribute('data-panel-id')) {
          element.classList.add('hide');
        } else {
          element.classList.remove('hide');
        }
      });
    });
  }

  // Add additional buttons that can control tabs
  const buttonsList = document.getElementsByClassName('tab-panel-button');
  for (let i = 0; i < buttonsList.length; i++) {
    buttonsList[i].addEventListener('click', function () {
      const current = document.getElementsByClassName('open');
      current[0].className = current[0].className.replace(' open', '');
      const tabItem = document.querySelector(`[data-panel-id="${this.getAttribute('data-panel-id')}"].tabs-nav__ul__li`);
      tabItem.className += ' open';
      Array.from(tabs).forEach((element) => {
        if (this.getAttribute('data-panel-id') == element.getAttribute('data-panel-id')) {
          element.classList.add('hide');
        } else {
          element.classList.remove('hide');
        }
      });
    });
  }
}
