let lastScrollTop = 0;
const header = document.querySelector('.top-nav');
const banner = document.querySelector('.alert-banner');
const navbarHeight = 80;
const bannerHeight = banner ? 48 : 0;
const threshold = banner ? navbarHeight + bannerHeight : navbarHeight;

const detailsElements = document.querySelectorAll('.top-menu__details');

window.addEventListener('scroll', (e) => {
  const scrolllTop = window.scrollY || this.document.documentElement.scrollTop;

  if (scrolllTop > threshold) {
    header.classList.remove('effect_slide_up');
    header.classList.add('effect_sroll');
    if (scrolllTop > lastScrollTop) {
      header.classList.add('effect_slide_up');
      header.classList.remove('effect_sroll');

      // Remove open from all details elements
      detailsElements.forEach((detail) => {
        detail.removeAttribute('open');
      });
    } else {
      // Only remove 'hiden' class if the user scrolled up at least 2 pixels
      if (lastScrollTop - scrolllTop > 2) {
        header.classList.remove('effect_slide_up');
        header.classList.add('effect_sroll');
      }
    }
  } else {
    header.classList.remove('effect_slide_up');
    header.classList.add('effect_sroll');
  }

  lastScrollTop = scrolllTop;
});
