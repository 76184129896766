import '../styles/main.scss';
import 'plyr/src/sass/plyr.scss';
import './agent-jobId';
import './blog-audio';
import './side-nav';
import './contact-tab';
import './relationships';
import './header-scroll';
import './youtube-embed-player';
import FilterBar from './filter-bar';
import { videoController } from './video-controller';
import Menu from './menu';
import PlayOnHover from './play-on-hover';
import { initializeAlert } from './alert-banner';
import TabsPanel from './tabs-panel';
import LinkAppendsInit from './link-appends';

function init() {
  const menu = new Menu('.navbar', true);
  initializeAlert();
  LinkAppendsInit();
  menu.asyncDropdown();
  if (document.querySelector('.filter-bar__menu')) {
    const filterBar = new FilterBar('.filter-bar__menu');
  }

  if (document.querySelector('.filter-bar__combined-menu--relationship')) {
    const filterBarRel = new FilterBar('.filter-bar__combined-menu--relationship');
  }

  const tabsPanel = new TabsPanel(
    '.tabs-nav',
    '.tabs',
  );
  const playOnHoverBlogVideos = new PlayOnHover();

  videoController('.a-video-controller__wrapper', '.a-video-controller__input');
}

window.addEventListener('load', init);
