const overlayImageDesktop = document.getElementById('youtube-overlay-image-desktop');
const overlayImageMobile = document.getElementById('youtube-overlay-image-mobile');
const youtubeIframe = document.getElementById('youtube-iframe');

if (overlayImageDesktop && youtubeIframe || overlayImageMobile && youtubeIframe)  {
    overlayImageDesktop.addEventListener('click', function () {
        overlayImageDesktop.style.display = 'none';
        const src = youtubeIframe.src;
        if (!src.includes('&autoplay=1')) {
            // Add autoplay parameter to start the video
            youtubeIframe.src = src + '&autoplay=1';
        }
    });

    overlayImageMobile.addEventListener('click', function () {
        overlayImageMobile.style.display = 'none';
        const src = youtubeIframe.src;
        if (!src.includes('&autoplay=1')) {
            // Add autoplay parameter to start the video
            youtubeIframe.src = src + '&autoplay=1';
        }
    });
}